import React from 'react'
import Layout from '../components/Layout'
import { Col, Container, Row } from 'reactstrap'

import Section from '../components/Section'
import RelatedContentWidget from '../components/RelatedContentWidget'
import { useLatestResources } from '../hooks/latest-resources'
import{ sortResources } from '../components/Resources'
import Link from '../components/Link'
import NotFoundImg from '../images/hero/not-found.png'


const getPopularContent = () => {
  let { blogs, guides, lives } =  useLatestResources()

  return sortResources([
    ...blogs.slice(0, 1),
    ...guides.slice(0, 1),
    ...lives.slice(0, 1),
  ])
}


export default () => {
  const popularContent = getPopularContent()

  return (
    <Layout>
      <Section className="NotFoundSection pb-3">
        <Container>
          <Row>
            <Col sm={6}>
              <h1 className="mb-4">Page not found.</h1>
              <p>
                <b>404 Error.</b>
                <br/>Sorry, that URL does not exist.
              </p>
              <Link
                localePrefix={true}
                href="/"
              >
                <b>Go to MyHR homepage</b>
              </Link>
            </Col>
            <Col sm={6}>
              <img src={NotFoundImg}/>
            </Col>
          </Row>
          <hr className="m-0"/>
        </Container>
      </Section>
  
      <Section className="mb-5">
        <Container>
          <h2 className="text-center mb-5">Latest resources</h2>
          <RelatedContentWidget
            title={null}
            items={popularContent}
            itemColProps={{
              md: 4
            }}
          />
        </Container>
      </Section>
  
    </Layout>
  )
}
